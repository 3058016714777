var updateButtonLink = function() {
  console.log('updateButtonLink');
  let regex = /tunnel-paiement\/(BVA|BVM)\/([a-z_]+)$/;
  let autoLoginParams = getAutoLoginParams();
  if (autoLoginParams) {
    console.log('autologinparams ', autoLoginParams);
    $('a').filter(function() {
      return regex.test($(this).attr('href').split('?')[0]);
    }).each(function() {
        let newHrefValue = $(this).attr('href');
        console.log('newHrefValue', newHrefValue);
        if (new URL(newHrefValue).search.length > 0) {
          newHrefValue += '&';
        } else {
          newHrefValue += '?';
        }
        $(this).attr('href', newHrefValue + autoLoginParams);
    });
  }
};

var getAutoLoginParams = function() {
  let currentLoginUrl = current_user.login_url;
  if (currentLoginUrl && currentLoginUrl.length > 0) {
    let parsedUrl = new URL(currentLoginUrl);
    return parsedUrl.search.substring(1);
  } else {
    return false;
  }
}

$(document).ready(function(){
  updateButtonLink();
});
