// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';

require("@rails/ujs").start();
require("jquery");
import '../../../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import '../../../node_modules/jquery-autocomplete/jquery.autocomplete.js';
import '../../../node_modules/mailcheck/mailcheck.jquery.json'
import 'src/javascript/jquery.geocomplete.js';


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//
// Or add images to default location
require.context('../images', true);

// -------------------------------------
// Import manually all you JS files here
// -------------------------------------


// Tells to compile all the scss
import 'src/stylesheets/application_static.scss';
import 'index.js';                        // Tells to compile all komponents JS

// -------------------------------------
// Import user disclaimer JS files
// -------------------------------------
if($('#iel-user-disclaimer-js')){
    import('src/javascript/user_disclaimer/user_disclaimer.js');
}

// -------------------------------------
// Import tunnel JS files
// -------------------------------------
if($('.tunnel')){
    import('src/javascript/tunnel.js');
}

if('#sign-up'){
    import('src/javascript/sign_up.js');
}

if($('#mail-suggestion-js')) {
    import('src/javascript/email_check.js');
}

if($('.closeInAppBrowser')) {
    import('src/javascript/site_opener.js');
}

// -------------------------------------
// Import iframe JS files
// -------------------------------------

import 'src/javascript/iframe/iframe.js';

// -------------------------------------
// Import Navbar JS files
// -------------------------------------

import 'src/javascript/navbar.js';

// Import AUTO ECOLE JS files
// -------------------------------------

import 'src/javascript/driving_school.js';

// -------------------------------------
// Import cours JS files
// -------------------------------------

import 'src/javascript/cours.js';

// -------------------------------------
// Import JS files for link updates
// -------------------------------------

import 'src/javascript/tunnel_button.js';
